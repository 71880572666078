@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

.filterTitles {
  font-weight: 600;
  color: whitesmoke;
}

.searchBox input {
  outline: none !important;
  cursor: text;
  width: 100%;
  min-height: 38px !important;
  border-radius: 4px !important;
  padding: 0 !important;
  margin: 0 !important;
  color: hsl(0, 0%, 20%) !important;
  text-indent: 10px;
  font-size: 16px;
  font-weight: 400;
  background-color: white !important;
  border-color: #1f99ff !important;
  border-width: 8px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  &:focus {
    border-color: #0080ff;
    box-shadow: 0 0 2px 2px #0080ff;
  }
}

.clearButton {
  cursor: pointer;
  min-height: 38px;
  border-radius: 4px;
  color: white;
  font-size: 28px;
  font-weight: 400;
  background-color: #cb3939;
  border-color: #cb3939;
  border-width: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  &:hover {
    background-color: #ee5151;
    border-color: #ee5151;
  }
}

.special-transmute {
  background: #d369e7;
}

.special-event {
  background: #47dba4;
  color: black;
}

.special-discontinued {
  background: #a0a0a0;
}

.rarity-Common {
  background: white;
  color: black;
}

.rarity-Uncommon {
  background: #2b83ff;
}

.rarity-Rare {
  background: #942dcf;
}

.rarity-Epic {
  background: #fc5656;
}

.rarity-Unique {
  background: #f8bd24;
}

.shuffleCard {
  display: inline-flex;
  position: relative;
  margin: 10px;
  width: 320px;

  border-style: solid;
  border-width: 1px;

  flex-flow: column;
}

.subtitle {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.lore {
  color: white;

  font-family: "Play", sans-serif;
  font-size: 16px;
  font-weight: 400;

  line-height: 1;
  letter-spacing: 1px;
}

.enchantable {
  background-image: url('../../assets/enchantable.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40px;
}

.socket {
  background-image: url('../../assets/socket.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 20px;
  width: 20px;
}

.socketExtender {
  background-image: url('../../assets/extender.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 20px;
  width: 20px;
}

.selectorButton-clear {
  background-color: #de4040;
  color: white;
  border: none;
  margin: 0;
  outline: 0;
  padding: 8px 10px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
}

.selectorButton-inactive {
  background-color: #d4d4d4;
  color: white;
  border: none;
  margin: 0;
  outline: 0;
  padding: 8px 10px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
}

.selectorButton-active {
  background-color: #3dafff;
  color: #ffffff;
  border-style: none;
  margin: 0;
  outline: 0;
  padding: 8px 10px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
}
