.Home {
  .fillImg {
    display: flex;
    border-radius: 5px;
    object-fit: cover;
    width: 100%;
    min-height: 250px;
    max-height: 400px;
  }

  .panelContent {
    display: inline-flex;
    width: 100%;
    flex-flow: column;
  }

  .panelTitle {
    font-weight: 700;
    font-size: 36px;
    padding: 20px;
  }

  .panelText {
    font-weight: 400;
    font-size: 20px;
    padding: 20px;
  }
}
