.barStyle-solid {
  background-color: #212121;
}

.barStyle-transparent {
  background-color: transparent;
}

.navButton {
  color: whitesmoke;
  background-color: #212121;

  box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.1) inset;
  border-radius: 1px;
  border: 1px solid black;
  border-bottom-width: 2px;

  margin: 4px 10px 2px 10px;
  outline: 0;
  padding: 5px 10px 7px 10px;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    transition: background-color 0.2s;
    transition-timing-function: ease-in-out;
    background-color: #2d2d2d;
  }
}

.headerBar {
  top: 0;
  right: 0;
  padding-top: 3px;
  padding-bottom: 3px;
  position: fixed;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 4;

  transition: background-color 0.15s;
  transition-timing-function: ease-in-out;

  .profileSection {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .facegemButton {
      box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.2) inset;
      border-radius: 1px;
      border: 0px solid #4e1f86;
      border-bottom-width: 2px;
    }
    .facegemButton:hover {
      box-shadow: 0px 0px 4px 2px rgba(255, 255, 255, 0.4) inset;

      .gemImg {
        animation-name: squishy;
        transform-origin: bottom;
        animation-timing-function: ease;
      }
    }
  }

  .logo {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 5px;
    margin-left: 5px;

    .logoButton {
      margin-bottom: 1px;
      margin-top: 2px;
      margin-left: 5px;
    }

    img {
      height: 100%;
      margin-right: 15px;
    }

    /* For making the logo text not-styled */
    a {
      text-decoration: none;
    }

    p {
      color: black;
      font-weight: 1000;
      margin-right: 30px;
      margin-left: 10px;
    }
  }

  .fa {
    padding: 10px;
    margin-right: 8px;
    color: white;
  }
}

.burgerContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.burgerNav {
  background-color: #191919;
  position: fixed;
  top: 56px;
  right: 0;
  padding: 1px 0px 5px;
  width: 100%;
  height: auto;

  z-index: 1000;

  transition: background-color 0.15s;
  transition-timing-function: ease-in-out;

  a {
    display: block;
    width: 200px;
    text-align: left;
    padding: 15px 100% 15px 15px;
    font-weight: 500;
    text-decoration: none;
    color: white;
  }
}
