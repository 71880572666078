.Footer {
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    font-size: 100%;
  }

  body {
    font-family: acumin-pro, system-ui, sans-serif;
    margin: 0;
    display: grid;
    grid-template-rows: auto 1fr auto;
    font-size: 14px;
    background-color: #f4f4f4;
    align-items: start;
    min-height: 100vh;
  }

  position: relative;

  .footer {
    display: flex;
    flex-flow: row wrap;
    padding: 30px 30px 20px 30px;
    color: #ffffff;
    background-color: #262626;
    border-top: 1px solid #000000;
    text-align: left;
    position: relative;
    z-index: 10;
  }

  .footer > * {
    flex: 1 100%;
  }

  .footer__addr {
    margin-right: 1.25em;
  }

  .footer__logo {
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: 6px;
  }

  .footer__addr h2 {
    margin-top: 1.3em;
    font-size: 15px;
    font-weight: 400;
  }

  .nav__title {
    font-weight: 500;
    font-size: 15px;
  }

  .footer address {
    font-style: normal;
    color: #999;
  }

  .footer__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    max-width: max-content;
    background-color: rgba(234, 234, 234, 0.84) !important;
    border-radius: 1px;
    color: #000000 !important;
    line-height: 0;
    margin: 0.6em 0;
    font-size: 1rem;
    padding: 0 1.3em;
  }

  .footer ul {
    list-style: none;
    padding-left: 0;
  }

  .footer li {
    line-height: 2em;
  }

  .footer a {
    text-decoration: none;
  }

  .footer__nav {
    display: flex;
    flex-flow: row wrap;
    margin-top: 0;
  }

  .footer__nav > * {
    flex: 1 50%;
    margin-right: 1.25em;
  }

  .nav__ul a {
    color: #999;
  }

  .nav__ul--extra {
    column-count: 2;
    column-gap: 1.25em;
  }

  .legal {
    display: flex;
    flex-wrap: wrap;
    color: #999;
  }

  .legal__links {
    display: flex;
    align-items: center;
  }

  .heart {
    color: #2f2f2f;
  }

  @media screen and (min-width: 24.375em) {
    .legal .legal__links {
      margin-left: auto;
    }
  }

  @media screen and (min-width: 40.375em) {
    .footer__nav > * {
      flex: 1;
    }

    .nav__item--extra {
      flex-grow: 2;
    }

    .footer__addr {
      flex: 1 0px;
    }

    .footer__nav {
      flex: 2 0px;
    }
  }
}
