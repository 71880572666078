.mainBanner {
  width: 100%;
  height: 30vw;
  min-height: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .videoBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 40vw;
    min-height: 350px;
    z-index: -1;
    object-fit: cover; /* combined with 'absolute', works like background-size, but for DOM elements */
  }

  .desktopTitle {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 70%;

    img {
      height: 100%;
    }
  }

  .mobileTitle {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 550px;

    img {
      width: 100%;
    }
  }
}
