.basicPanel {
  color: whitesmoke;
  padding: 15px;
  flex-direction: column;

  .bodyTitle {
    font-weight: 800;
    font-size: 30px;
    margin: 10px;
  }
}
