.Guide {
  .infoBanner {
    width: 100%;
    padding: 15px 0;
    text-align: center;
    color: whitesmoke;
    background-color: #3f6ee3;

    p {
      font-weight: 650;
      font-size: 1.3em;
      padding: 0;
      margin: 0;
    }
  }

  .buffer {
    padding-top: 20px;
  }

  .accordion {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
  }

  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
  }

  .accordion__button {
    position: relative;
    width: calc(100% - 36px);
    padding: 14px;
    margin: 0px 4px 0 4px;
    border-radius: 5px;
    color: whitesmoke;
    background-color: #de4040;

    text-align: left;
    font-weight: 600;
    z-index: 3;

    &:focus {
      outline: none;
    }
  }

  .accordion__item {
    margin-bottom: 10px;
  }

  .accordion__panel {
    background-color: whitesmoke;

    .accordion__panel {
      background-color: rgba(0, 0, 0, 0.08);
    }

    position: relative;
    text-align: left;

    margin: -4px 4px 0 4px;
    border-radius: 0 0 10px 10px;
    z-index: 1;

    color: #2e2e2e;
    font-weight: 500;

    padding: 20px 20px;

    p {
      margin: 0;
    }

    animation: fadein 0.1s ease-in;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
