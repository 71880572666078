.copyContainer {
  display: inline-block;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  z-index: 1;
  cursor: pointer;

  box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.1) inset;
  border-radius: 1px;
  border: 0px solid #335717;
  border-bottom-width: 2px;

  .copyButton {
    display: flex;
    flex-direction: row;
    height: 100%;
    font-size: calc(0.7rem + 0.7vw);

    .divider {
      margin: 0 7px;
      border-left: 1px solid;
      border-color: rgba(0, 0, 0, 0.3);
    }

    svg {
      display: inline;
      width: calc(0.7rem + 0.7vw);
    }
  }
}
