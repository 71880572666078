.buddy {
  position: absolute;
  right: 50px;
  top: -120px;
  height: 280px;

  z-index: 4;

  transform: rotate(10deg);
}

.hit-marker {
  position: fixed;
  z-index: 1000;

  cursor: not-allowed !important;
  pointer-events: all !important;

  color: white;
  font-family: 'Lucida Console', 'Courier New', monospace;
  font-weight: 700;
  font-size: 20px;

  padding: 1px 8px;
  background-color: rgba(0, 0, 0, 0.35);

  transition: all 0.1s linear;
}

.hitShake {
  animation: shake 1s;
  animation-iteration-count: infinite;
  filter: sepia(100%) saturate(400%) brightness(50%) hue-rotate(310deg);
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(14deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(6deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(14deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(10deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(14deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(6deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(10deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(6deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(14deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(10deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(6deg);
  }
}
