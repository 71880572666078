.playersOnlineBanner {
  width: 100%;
  padding: 15px 0;
  text-align: center;
  color: whitesmoke;
  background-color: #de4040;

  box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.3) inset;
  border: 0px solid #641313;
  border-bottom-width: 2px;

  p {
    font-weight: 650;
    font-size: 1.3em;
    padding: 0;
    margin: 0;
  }
}
